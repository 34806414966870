<template>
  <div class="bg">
    <div class="container-fluid">
      <div class="row align-items-center">
        <div class="col-md-5 col-lg-4 mx-auto mt-10 box">
          <div class="login-form mt-3 mt-md-0 mx-auto col-md-12 col-lg-10">
            <div style="text-align: center">
              <img
                style="margin-bottom: 50px; margin-top: 50px"
                src="../../img/logoTeros.png"
              />
            </div>
            <kinput
              type="password"
              key="org1"
              v-model="senha"
              placeholder="Digite a nova senha"
            >
              Senha
            </kinput>

            <kinput
              type="password"
              key="org2"
              v-model="confirmar_senha"
              placeholder="Repita a nova senha"
            >
              Repita a senha
            </kinput>

            <div class="mb-3 requerimentos">
              <div :class="{ 'requerimento-okay': minChars }">
                <v-icon v-if="minChars">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
                Mínimo de 8 caracteres
              </div>

              <div :class="{ 'requerimento-okay': minUppercase }">
                <v-icon v-if="minUppercase">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
                Mínimo 1 letra maiúscula
              </div>

              <div :class="{ 'requerimento-okay': minLowercase }">
                <v-icon v-if="minLowercase">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
                Mínimo 1 letra minúscula
              </div>

              <div :class="{ 'requerimento-okay': minDigit }">
                <v-icon v-if="minDigit">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
                Mínimo 1 número
              </div>

              <div :class="{ 'requerimento-okay': minSpecial }">
                <v-icon v-if="minSpecial">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
                Mínimo 1 especial dentre $!?{}()*&@#
              </div>

              <div :class="{ 'requerimento-okay': bothEqual }">
                <v-icon v-if="bothEqual">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
                Ambos os campos devem ser iguais
              </div>
            </div>

            <div class="mx-auto">
              <div style="text-align: center">
                <button
                  @click="doResetPassword()"
                  class="btn btn-galapos"
                  type="submit"
                  :disabled="!isValid"
                >
                  <span>Redefinir senha</span>
                  <v-icon small color="#FFF">mdi-chevron-right</v-icon>
                </button>
              </div>
            </div>

            <div style="text-align: center">
              <img style="margin-top: 150px" src="../../img/logoGalapos.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created: function () {
    const { token } = this.$route.params;

    if (!token) {
      this.$notify(
        "Link inválido. Por favor, refaça o pedido de redefinir senha."
      );
      this.$router.push({ name: "homepage" });
    }
  },
  components: {
    kinput: () => import("@/components/kInput.vue"),
  },
  computed: {
    resourceReset: function () {
      return this.apiResource(`/v1/redefinir-senha`);
    },
    fields: function () {
      return [
        {
          key: "senha",
          name: "Nova senha",
          type: this.$fieldTypes.PASSWORD,
        },
        {
          key: "confirmar_senha",
          name: "Confirme nova senha",
          type: this.$fieldTypes.PASSWORD,
        },
      ];
    },
    senhaDigitada: function () {
      return !!this.senha && this.senha.length >= 1;
    },
    minChars: function () {
      return this.senhaDigitada && this.senha.length >= 8;
    },
    minUppercase: function () {
      return (
        this.senhaDigitada && this.senha.replace(/[^A-Z]/g, "").length >= 1
      );
    },
    minLowercase: function () {
      return (
        this.senhaDigitada && this.senha.replace(/[^a-z]/g, "").length >= 1
      );
    },
    minDigit: function () {
      return this.senhaDigitada && this.senha.replace(/\D/g, "").length >= 1;
    },
    minSpecial: function () {
      return (
        this.senhaDigitada &&
        /_|[^\w\s]/g.test(this.senha)
      );
    },
    bothEqual: function () {
      return this.senhaDigitada && this.confirmar_senha === this.senha;
    },
    isValid: function () {
      return this.minChars && this.minUppercase && this.minLowercase && this.minDigit && this.minSpecial && this.bothEqual;
    },
  },
  data: function () {
    return {
      senha: "",
      confirmar_senha: "",
    };
  },
  methods: {
    doResetPassword: function () {
      if (this.isValid) {
        this.resourceReset
          .save({
            senha: this.senha,
            hash: this.$route.params.token,
          })
          .then(() => {
            this.$notify({
              group: "geral",
              duration: 7000,
              type: "success",
              title: "Senha alterada com sucesso.",
              text: "Por favor, se autentique para acessar a plataforma Teros.",
            });
            this.$router.push({ name: "homepage" });
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.bg {
  background: url("../../img/login.jpg") center;
  background-size: cover;
  height: 100vh;
}

.box {
  background: url("../../img/circulos.png") no-repeat;
  background-size: 100%;
  width: 625px;
}

.btn-galapos {
  background: #7dbacc;
  box-shadow: 0px 8px 16px rgba(0, 126, 255, 0.16);
  border-radius: 8px;
  color: white;
}

.requerimentos {
  font-size: 16px;

  &,
  .v-icon {
    color: #ffffffaa;
  }

  .requerimento-okay,
  .requerimento-okay .v-icon {
    color: #58ad58;
  }
}
</style>
